









import { Vue, Component, Prop } from "vue-property-decorator";
import { Checkbox, CheckboxGroup } from "element-ui";
@Component({
    components: {
        "el-checkbox-group": CheckboxGroup,
        "el-checkbox": Checkbox
    }
})
export default class FormItemCheckbox extends Vue {
    @Prop({
        type: [String, Object, Array],
        required: false,
        default: () => ({})
    })
    value: any; //表单数据

    @Prop({ type: Object, required: true, default: () => {} })
    formItemOpt: any; //表单参数

    options: object = []; //radio项参数
    val: any = ""; //RadioGroup 绑定值

    // 钩子，选中值发生变化时触发
    change(data: any) {
        let _data = data.sort((a: any, b: any) => {
            return Number(a) - Number(b);
        });
        this.$emit("onchange", String(_data));
    }

    getOption() {
        const getopt = this.$api.apiList[this.formItemOpt.apikey];
        const opt = getopt();
        this.$axios({
            url: opt.url,
            method: opt.method,
            headers: opt.headers
        })
            .then((res: any) => {
                this.options = res.data[this.formItemOpt.apiResKey];
            })
            .catch((res: any) => {});
    }

    async isArray(data: any) {
        return Array.isArray(data);
    }
    mounted() {
        this.isArray(this.value).then((res: any) => {            
            if (res === true) {
                this.val = this.value;
            } else {
                if (this.value.length != 0) {
                    this.val = this.value.split(",");
                } else {
                    this.val = [];
                }
            }
        });
        this.getOption();
    }
}
